import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ResearchQuestions } from '../../components';
import { colors, phases, methods, tasks, papers } from '../../data';
import { isEmpty } from '../../helpers';

/**************************************************************************************************** 
 * REUSABLE
****************************************************************************************************/

const DialogContentWrapper = styled("div", { shouldForwardProp: prop => prop !== "color" })(({ theme, color }) => ({
    '& .main-title': { padding: 30, background: color, color: '#FFF', fontWeight: 500, },
    '& .main-content': { padding: 40,
        '& .section': { paddingBottom: 20,
            '& .section-title': { color: color, fontWeight: 500, },
        },
    }
}));

const Section = ({ title, text }) => (
    <div className="section">
        <Typography className="section-title" variant="h5">{title}</Typography>
        <p>{text}</p>
    </div>
);

/**************************************************************************************************** 
 * DIALOG TYPES
****************************************************************************************************/

function Phase({ number }) {
    return (
        <DialogContentWrapper color={colors.phase}>
            <Typography className="main-title" variant="h5">{phases[number].year} - {phases[number].title}</Typography>
            <div className="main-content">
                <div className="section">
                    <Typography className="section-title" variant="h5">Aim</Typography>
                    <p>{phases[number].aim}</p>
                </div>
                <div className="section">
                    <Typography className="section-title" variant="h5">Research Questions</Typography>
                    <ResearchQuestions 
                        questions={phases[number].questions}
                    />
                </div>
            </div>
        </DialogContentWrapper>

    )
}

function Method({ number }) {
    return (
        <DialogContentWrapper color={colors.method}>
            <Typography className="main-title" variant="h5">{methods[number].title}</Typography>
            <div className="main-content">
                {methods[number].method === 'survey' && (
                    <React.Fragment>
                        {!isEmpty(methods[number].mainObjective) && <Section title="Main Objective" text={methods[number].mainObjective} />}
                        {!isEmpty(methods[number].timeFrame) && <Section title="How Long will the Survey Run For?" text={methods[number].timeFrame} />}
                        {!isEmpty(methods[number].sampleSize) && <Section title="What is the Minimum Number of Participants that Will be Surveyed?" text={methods[number].sampleSize} />}
                        {!isEmpty(methods[number].stratification) && <Section title="Who are the Participants that are Expected to be Recruited?" text={methods[number].stratification} />}
                        {!isEmpty(methods[number].surveyLink) && <Section title="Is there a Link Available for the Survey?" text={methods[number].surveyLink} />}
                    </React.Fragment>
                )}
                {methods[number].method === 'interview' && (
                    <React.Fragment>
                        {!isEmpty(methods[number].mainObjective) && <Section title="Main Objective" text={methods[number].mainObjective} />}
                        {!isEmpty(methods[number].sampleSize) && <Section title="How many Gastroenterologists will be Interviewed?" text={methods[number].sampleSize} />}
                    </React.Fragment>
                )}
                {methods[number].method === 'prototyping' && (
                    !isEmpty(methods[number].description) ? 
                        <Section title="Description" text={methods[number].description} /> :
                        <Typography variant="h6">Sorry, there is no content on this activity at the moment. Content will be added soon...</Typography>
                )}
            </div>
        </DialogContentWrapper>

    )
}

function Task({ number }) {
    return (
        <DialogContentWrapper color={colors.task}>
            <Typography className="main-title" variant="h5">{tasks[number].title}</Typography>
            <div className="main-content">
                <Section title="Task Description" text={tasks[number].description} />
            </div>
        </DialogContentWrapper>

    )
}

function Paper({ number }) {
    return (
        <DialogContentWrapper color={colors.paper}>
            <Typography className="main-title" variant="h5">{papers[number].title}</Typography>
            <div className="main-content">
                <div className="section">
                    <Typography className="section-title" variant="h5">Aim</Typography>
                    <p>{papers[number].aim}</p>
                </div>
                <div className="section">
                    <Typography className="section-title" variant="h5">Objectives</Typography>
                    <ol>
                        {papers[number].objectives.map((objective, index) => <li key={`objective-${index}`}>{objective}</li>)}
                    </ol>
                </div>
            </div>
        </DialogContentWrapper>

    )
}

/**************************************************************************************************** 
 * EXPORT MDOULE
****************************************************************************************************/

const DialogTypes = {
    Phase,
    Method,
    Task,
    Paper,
}

export default DialogTypes;