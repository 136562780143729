import React from 'react';
import { Typography } from '@mui/material';
import { ResearchQuestions, PhaseHeader } from "../../components";
import { phases } from "../../data";

const PHASE_NUM = 1;

export default function UsabilityAdoption() {
    const [activeChip, setActiveChip] = React.useState('aim');
    return (
        <>
            <PhaseHeader
                number={PHASE_NUM}
                year={phases[PHASE_NUM].year}
                title={phases[PHASE_NUM].title}
                activeChip={activeChip}
                onChangeChip={setActiveChip}
            />
            {activeChip === 'aim' && (<Typography className="timeline-section-aim" variant="h6">{phases[PHASE_NUM].aim}</Typography>)}
            {activeChip === 'questions' && (<ResearchQuestions questions={phases[PHASE_NUM].questions} references={phases[PHASE_NUM].references} />)}
        </>
    )
}