import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '../theme';
import { ModalContext } from '../contexts';
import { ScrollToTop, } from '../helpers';
import RouteWrapper from './RouteWrapper';
// Standard Pages
import Home from './1_Home';
import Background from './2_Background';
import Phases from './3_Phases';
import Timeline from './4_Timeline';
import Papers from './5_Papers';
import ErrorPage from './Error';

function App() {
    const [modal, dispatchModal] = React.useReducer(ModalContext.reducer, ModalContext.initialState);
    return (
        <ModalContext.context.Provider value={{ modal, dispatchModal }}>
            <Router>
                <MuiThemeProvider>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <RouteWrapper exact path="/background" component={Background} />
                        <RouteWrapper exact path="/phases" component={Phases} />
                        <RouteWrapper exact path="/timeline" component={Timeline} />
                        <RouteWrapper exact path="/papers" component={Papers} />
                        <Route component={ErrorPage} />
                        {/* <Route exact path="/support" component={Support} />  */}
                    </Switch>
                </MuiThemeProvider>
            </Router>
        </ModalContext.context.Provider>
    );
}

export default App;