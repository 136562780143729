import React from 'react'
import { Containers, Cards } from "../../theme";

export default function DetectingCancer() {
    return (
        <Containers.Cards className="full-width">
            <Cards.Section
                cardDesc={<>Currently, the Gold Standard approach to screening for Colon Cancer is the Immunochemical Faecal 
                Occult Blood Test (iFOBT), which searches for microscopic traces of blood in patient stools.</>}
            />
            <Cards.Section 
                cardDesc={<>A positive iFOBT test does not mean the patient has cancer - just that there is blood in the stools. 
                If the result is positive, a colonoscopy must be performed to confirm diagnosis.</>}
            />
            <Cards.Section 
                cardDesc={<>The objective of this two-stage screening approach is to detect colon cancer at a stage where it is easier 
                    to treat. This <span className="emphasise primary">reduces the risk</span> of colon cancer-related death.</>}
                references={[
                    { title: "WHO: Screening programmes: a short guide", link: "https://apps.who.int/iris/bitstream/handle/10665/330829/9789289054782-eng.pdf" },
                    { title: "Positive impact of a faecal-based screening programme on colorectal cancer mortality risk", link: "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0253369" },
                ]}
            />
        </Containers.Cards>
    )
}