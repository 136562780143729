import React from 'react'
import { Containers, Cards } from "../../theme";

export default function ScreeningPolyps() {
    return (
        <Containers.Cards className="full-width">
            <Cards.Section
                cardType="blurb-list"
                background="secondary"
                cardDesc="The Gold Standard for the detection of pre-cancerous colonic polyps is the colonoscopy. Colonoscopies have never been considered as a viable option for cancer screening, let alone polyp screening due to:"
                sections={["Adverse Effects and Risks", "Poor Patient Acceptability", "Prohibitive Costs and Workforce Limitations"]}
                references={[
                    { title: "Cancer Council Australia: Screening Test Accuracy", link: "https://wiki.cancer.org.au/australia/Clinical_question:Colorectal_cancer_screening_test_accuracy" }
                ]}
            />
            <Cards.Section
                cardType="blurb-list"
                background="secondary"
                cardDesc="Less invasive tests, such as the stool-based iFOBT, stool-based DNA test, or blood-based DNA test, have all been unsuccessful in identifying precancerous polyp biomarkers, showing low sensitivity:"
                sections={["iFOBT @ 16 - 64%", "Faecal DNA Testing @ 17 - 46%", "Plasma DNA Testing @ 4 - 13%"]}
                references={[
                    { title: "Cancer Council Australia: National Cancer Control Policy - Colonoscopy", link: "https://wiki.cancer.org.au/policy/Bowel_cancer/Screening#Colonoscopy" }
                ]}
            />
        </Containers.Cards>
    )
}