const methods = {
    1: { 
        method: "survey",
        title: "Patient Survey on Perceptions, Attitudes, and Concerns Towards Capsule Based Screening", 
        mainObjective: "To identify attitudes, concerns, and perceptions towards capsule-based screening as a modality for the preventative screening of colon cancer, as compared with the iFOBT.",
        timeFrame: "Over a three month period.",
        sampleSize: "The minimum sample size will be 300 participants",
        stratification: "We will stratify the participants into three groups: (1) Age 50-74 that currently participate in iFOBT screening, (2) Age 50-74 that do not participate in iFOBT screening, (3) Age Under 50.",
        surveyLink: "",
    },
    2: { 
        method: "interview",
        title: "Semi-Structured Interviews with Gastroenterologists on Perceptions, Attitudes, and Concerns Towards Capsule Based Screening", 
        mainObjective: "To identify the attitudes, concerns, and perceptions towards capsule-based screening as a modality for the preventative screening of colon cancer, as compared with the iFOBT.",
        sampleSize: "We will interview 5 senior gastroenterologists",
    },
    3: { 
        method: "prototyping",
        title: "Prototyping with Clinicians for Clinician User Interface", 
        description: `It is a known fact that Clinicians have a disdain towards electronic health records and health informatics systems. From complaints around information overload, to the infinite cycle of 
        clicking (~300 clicks per hour), to time taken away from patients, and to avoidable errors - health systems have failed to deliver on their promises. The reason is often due to the fact that the systems
        are designed by Engineers, designed in silos away from the healthcare context, where knowledge about the heterogeneity of clinical workflow is non-existent. Therefore, we are applying a methodological
        framework to co-design the user interface with clinicians to maximise the likelihood of uptake by clinicians and the health system.`
    },
    4: { 
        method: "prototyping",
        title: "AI Algorithm Development: Incorporating Dynamic AI into a Clinical User Interface", 
        description: `While AI has the potential to improve over time, it is not yet clear how a dynamic AI system can be integrated into the clinical workflow.
            AI requires labelled data and as part of the initial training procedure, medical datasets are often labelled by at least 2 clinicians to minimise the
            risk of bias and mitigate the problem of inter-observer variability. How does this occur in the real-world when one clinician is responsible for diagnosis?
            How does a wrong diagnosis affect the AI? How do we dynamically address issues of class imbalance? And how do we ensure that a new model will have an improved
            efficacy, or for the minimum, the same efficacy? This introduces trust, integrity and validation issues. In this phase, we will unpack and develop a robust
            approach that maximises the utility of AI in the real world.
        ` 
    },
    5: { 
        method: "prototyping",
        title: "Co-Designing a Next Generation Ingestible Capsule Based on Patient Needs", 
        description: `A capsule that works is not necessarily a capsule that people will use. As we work towards re-engineering the electronics
            from the ground up, we will carefully work with patients to ensure that our hardware is robust against their concerns.`,
    },
    6: { 
        method: "prototyping",
        title: "Co-Designing with Patients for Patient User Interface", 
        description: `When a patient receives their result, how do we communicate and deliver the message to the patient? What happens if it is
        a positive result? We will be working with patients to develop an experience that is seamless, with minimal anxiety from the moment the
        capsule is unpacked to the completion of its transit and dissemination of the result.`,
    },
}

export default methods;