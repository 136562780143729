import { styled } from '@mui/material/styles';

/**************************************************************************************************** 
 * FORM MAIN
****************************************************************************************************/

const FormWrapper = styled('div')(({ theme }) => ({
    maxWidth: 600,
    margin: '50px auto',
    display: 'flex',
    flexDirection: 'column',
}));

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Forms = {
    Main: FormWrapper,
}

export default Forms;