import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LooksOne as Looks1, LooksTwo as Looks2, Looks3, Looks4, Looks5, Looks6 } from '@mui/icons-material';
import { isEmpty } from '../helpers';


/**************************************************************************************************** 
 * VIEW CONTAINER
****************************************************************************************************/

const ViewContainer = styled("div")(({ theme }) => ({
    position: 'relative',
}));

/**************************************************************************************************** 
 * SECTION CONTAINER
****************************************************************************************************/

export const SectionHeading = styled(Typography)(({ theme }) => ({
    fontFamily: "'Poppins', sans-serif", fontWeight: 500, color: '#FFF',
    '& .section-number': { width: '3em', height: '3em', color: theme.palette.primary.main, },
    '& .section-text': { marginBottom: 40 },
    '& .section-blurb': { fontSize: '0.5em', maxWidth: 800, margin: '0 auto', lineHeight: '1.5', marginBottom: 30, }
}));

const SectionWrapper = styled("div")(({ theme, background }) => ({
    background: background ? background : 'none', textAlign: 'center',
}));

const SectionComponent = ({ number, title, blurb, children }) => {

    const Icons = {
        1: <Looks1 />,
        2: <Looks2 />,
        3: <Looks3 />,
        4: <Looks4 />,
        5: <Looks5 />,
        6: <Looks6 />,
    }

    return (
        <SectionWrapper>
            {!isEmpty(title) && (
                <SectionHeading>
                    {!isEmpty(number) && (<div className="section-number">{Icons[String(number)]}</div>)}
                    {!isEmpty(title) && (<div className="section-text">{title}</div>)}
                    {!isEmpty(blurb) && (<div className="section-blurb">{blurb}</div>)}
                </SectionHeading>
            )}
            {children}
        </SectionWrapper>
    )
}

/**************************************************************************************************** 
 * CARDS CONTAINER
****************************************************************************************************/
const CardsWrapper = styled("div")(({theme}) => ({
    textAlign: 'center', display: 'flex', flexWrap: 'wrap', maxWidth: 800, margin: '0 auto',
    '&.full-width': { maxWidth: '100%' },
    '&.centered': { justifyContent: 'center', alignItems: 'center', },
}));

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Containers = {
    View: ViewContainer,
    Section: SectionComponent,
    Cards: CardsWrapper,
};

export default Containers;