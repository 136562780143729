import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { Containers, Banners, Logos, Buttons } from '../../theme';
import { Dialog, Pages } from '../../components';
import { Favorite as LoveIcon, ExpandMore as ExpandIcon, EmojiEmotions as SmileyIcon } from '@mui/icons-material';
import { ModalContext } from '../../contexts';

export default function Error() {
    const history = useHistory();
    const { dispatchModal } = React.useContext(ModalContext.context);
    return (
        <Containers.View>
            <Dialog />
            <Logos.Text className="mini" white={true} position="top-left" onClick={() => history.push('/')} />
            <Banners.Main error={true}>
                <div className="content">
                    <Typography className="logo-text">NΞO</Typography>
                    <Typography className="main-title" variant="h2">This Page is Coming Soon...</Typography>
                    <Typography className="sub-title" variant="h5">
                        There is an ocean of knowledge we need to communicate to you. This page will be updated soon with more
                        information. We can't wait to show you what we're working on.
                    </Typography>
                    <Buttons.Dual 
                        buttons={[
                            <Button variant="action" startIcon={<SmileyIcon />} onClick={() => dispatchModal({ type: "OPEN", payload: <Pages /> })}>Change Page</Button>,
                            <Button variant="action" startIcon={<LoveIcon />} onClick={() => history.push('/')}>Return Home</Button>
                        ]}
                    />
                </div>
            </Banners.Main>
        </Containers.View>
    );
}