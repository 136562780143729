import React from 'react';
import { Dialog } from '@mui/material';
import { ModalContext } from '../contexts';

// export const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

// export const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })(({ theme, open }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen, }),
//     ...(open && {
//         marginLeft: drawerWidth, width: `calc(100% - ${drawerWidth}px)`, transition: theme.transitions.create(['width', 'margin'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen, }),
//     }),
// }));

const DialogComponent = () => {
    const { modal, dispatchModal } = React.useContext(ModalContext.context);
    const { open, component } = modal;

    return (
        <Dialog open={open} onClose={() => dispatchModal({ type: "CLOSE" })}>
            {component}
        </Dialog>
    )
}

export default DialogComponent;