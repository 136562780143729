import React from 'react';
import { Containers, Banners, Timelines } from '../../theme';
import UsabilityAdoption from './1_UsabilityAdoption';
import ClinicianAI from './2_ClinicianAI';
import HardwareDev from './3_HardwareDev';
import PatientUX from './4_PatientUX';

const sections = [<UsabilityAdoption />, <ClinicianAI />, <HardwareDev />, <PatientUX />];

export default function Phases() {
    return (
        <Containers.View>
            <Banners.Mini
                title="Research Phases & Aims"
                blurb={<>Over the next 5 years, we are solving the socio-technical, software, and hardware problems
                    that are needed to enable equitable access to capsule-based technology, so that any patient has the ability
                    to find colon cancer in its <span className="emphasise primary">pre-cancerous state</span> and prevent it from
                    developing in the first place, from the comfort of their own home.</>}
            />
            <Timelines.Path 
                sections={sections}
            />
        </Containers.View>
    )
}
