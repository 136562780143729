import React from 'react';
import { Typography } from '@mui/material';
import { Containers, Banners, Cards, Radios, SVGFilters } from '../../theme';
import { papers, plans } from '../../data';
import { Legend } from '../../components';

const Data = { papers, plans };

const legend = [
    { label: 'Scheduled to Write', color: '#6d44b5' },
    { label: 'Currently Drafting', color: '#fe8d58' },
    { label: 'Under Review', color: '#f44336' },
    { label: 'Published', color: '#009688' },
];

export default function Docs() {
    const [radioValue, setRadioValue] = React.useState('papers');

    const onChangeRadio = (event) => {
        setRadioValue(event.target.value);
    }

    return (
        <Containers.View>
            <Banners.Mini
                title="Publications & Plans"
                blurb={<React.Fragment>We believe transparency and openness should govern healthcare technology design and dissemination.
                    Below you can access draft publications and plans that we are developing in relation to this this project. This page 
                    and the corresponding documents are <span className="emphasise primary">dynamic works</span> and will be updated regularly.
                </React.Fragment>}
                additionalComponent={<>
                    <Typography variant="h5" color="primary" style={{marginTop: 20, fontWeight: 500, }}>Last Updated: 13/10/21</Typography>
                    <Radios.Main
                        selected={radioValue}
                        onChange={onChangeRadio}
                        options={[
                            { label: "Publications", value: "papers" },
                            { label: "Plans", value: "plans", },
                        ]}
                    />
                    <Legend legend={legend} />
                </>}
            />
            <Containers.Cards className="full-width centered" sx={{ paddingBottom: '80px' }}>
                <SVGFilters.CardBlobSmooth />
                {Object.entries(Data[radioValue]).map(([key, value], index) => {
                    let cardNumber = index + 1;
                    return (
                        <Cards.Blob
                            key={`card-${key}-${cardNumber}-${index}`}
                            cardNumber={cardNumber}
                            cardLabel={radioValue === "papers" ? "Paper" : "Plan"}
                            cardState={value.state}
                            title={value.title}
                            cardDesc={value.aim}
                            link={value.link}
                        />
                    )
                })}
            </Containers.Cards>
        </Containers.View>
    )
}