import React from 'react';
import { Containers, Cards } from "../../theme";

export default function CancerChallenge() {
    return (
        <Containers.Cards className="full-width">
            <Cards.Section 
                cardDesc={<>Colorectal cancer is the <span className="emphasise primary">second leading cause of cancer</span> in Australia (and the world).</>}
                references={[
                    { title: "Bowel cancer (Colorectal cancer) in Australia statistics", link: "https://www.canceraustralia.gov.au/cancer-types/bowel-cancer/statistics" },
                ]}
            />
            <Cards.Section 
                cardDesc={<>Colorectal cancer is the <span className="emphasise primary">second leading cause</span> of <span className="emphasise danger">cancer-related death</span> in Australia (and the world).</>}
                references={[
                    { title: "Global Cancer Statistics 2020: GLOBOCAN Estimates of Incidence and Mortality Worldwide for 36 Cancers in 185 Countries", link: "https://acsjournals.onlinelibrary.wiley.com/doi/full/10.3322/caac.21660" },
                ]}
            />
            <Cards.Section 
                cardDesc={<>Colorectal cancer <span className="emphasise primary">incidence is increasing</span> in Australia (and the world).</>}
                references={[
                    { title: "National Cancer Control Indicators: Cancer Incidence", link: "https://ncci.canceraustralia.gov.au/diagnosis/cancer-incidence/cancer-incidence" },
                ]}
            />
            <Cards.Section 
                cardDesc={<>Colorectal cancer <span className="emphasise primary">incidence</span> has <span className="emphasise danger">doubled in people under the age of 50</span> since the 1990s, with rising mortality.</>}
                references={[
                    { title: "Bowel Cancer Australia: Young Onset", link: "https://www.bowelcanceraustralia.org/young-onset" },
                ]}
            />
            <Cards.Section 
                cardDesc={<>When colorectal cancer is detected early (Stage 1), 5-year survival rate ranges from 90 - 99%. When detected late (Stage 4), the survival rate is under 10%.</>}
                references={[
                    { title: "Bowel cancer (Colorectal cancer) in Australia statistics", link: "https://www.canceraustralia.gov.au/cancer-types/bowel-cancer/statistics" },
                ]}
            />
            <Cards.Section
                className="emphasise" 
                cardDesc={<>Therefore, reducing the burden of colorectal cancer through early detection is a global priority.</>}
            />
        </Containers.Cards>
    )
}
