import React from 'react';
import clsx from 'clsx';
import { Typography, Chip, } from '@mui/material';
import { styled } from '@mui/material/styles';

const options = [
    { label: 'Aim', chip: 'aim' },
    { label: 'Research Questions', chip: 'questions' },
];

const TimelineHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main, fontWeight: 500,
    "& .year-chip": { background: theme.palette.primary.main, color: '#FFF', fontWeight: 600, marginRight: 10, },
}));

const TimelineChips = styled("div")(({theme}) => ({
    marginTop: 10,
    '& .option-chip': {
        background: 'rgba(255,255,255,.2)', color: 'rgba(255,255,255,.5)', marginRight: 10, fontWeight: 500,
        '&:hover': { background: 'rgba(255,255,255,.8)', color: 'rgba(0,0,0,.5)' },
        '&.active': { background: '#FFF', color: '#000', },
    }
}));

export default function PhaseHeader({ number, year, title, activeChip, onChangeChip, }) {
    return (
        <React.Fragment>
            <TimelineHeading variant="h4">
                <Chip className="year-chip" label={year} />
                Phase {number}: {title}
            </TimelineHeading>
            <TimelineChips>
                {options.map((option, index) => <Chip key={`option-chip-${index}`} className={clsx("option-chip", { active: activeChip === option.chip })} onClick={() => onChangeChip(option.chip)} label={option.label} />)}
            </TimelineChips>
        </React.Fragment>
    )
}