import React from 'react';

const context = React.createContext();

// Tag reducer initial state
const initialState = {
    open: false,
    component: null,
};

// Tag reducer
const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case "OPEN":
            return {
                ...state,
                open: true,
                component: payload,
            }
        case "CLOSE": {
            return {
                open: false,
                component: null,
            }
        }
        default:
            return state;
    }
}

const ModalContext = {
    context,
    initialState,   
    reducer,
};

export default ModalContext;