import React from 'react';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

/**************************************************************************************************** 
 * ACCORDION: MAIN
****************************************************************************************************/

const AccordionWrapper = styled(Accordion)(({ theme }) => ({
    background: 'none', marginBottom: '20px !important', borderBottom: `4px dashed #FFF`, boxShadow: 'none',
    '&::before': { display: 'none', },
    '& .MuiSvgIcon-root': { width: '2em', height: '2em', color: '#FFF' },
    '& .MuiAccordionSummary-content': { padding: 10, },
    '& .MuiAccordionDetails-root': { display: 'flex', flexWrap: 'wrap', paddingBottom: 40, },
    '& .accordion-title': { color: "#FFF", fontStyle: "italic" },
    [theme.breakpoints.down('sm')]: { maxWidth: '100%' },
}));

const AccordionComponent = ({ title, children }) => {
    return (
        <AccordionWrapper>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="accordion-title" variant="h4">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </AccordionWrapper>
    )
}


/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Accordions = {
    Main: AccordionComponent
}

export default Accordions;