import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ButtonGroup, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { ModalContext } from '../contexts';
import { Pages } from '.';

export const AppHeader = styled("div")(({ theme }) => ({
    position: 'fixed', bottom: 15, right: 10, zIndex: 999, display: 'flex', alignItems: 'center',
    '& .btn-group': { height: '50px', borderRadius: 50,
        '& .btn-left': {
            backgroundColor: '#FFF', color: '#7769c4', paddingLeft: '20px', borderRadius: '50px 0 0 50px', border: 'none !important',
            '&:hover': { backgroundColor: '#7769c4 !important', color: '#FFF !important', }
        },
        '& .btn-right': { borderRadius: '0 50px 50px 0', backgroundColor: '#7769c4' }
    },
    '& .chips': {
        '& .header-chip': {
            background: '#FFF', color: "#000", margin: '5px', fontWeight: 500, cursor: 'pointer',
            '&:hover, &.active': { background: theme.palette.primary.main, color: '#FFF', },
        }
    }
}));

const Header = () => {
    const { path } = useRouteMatch();
    const { dispatchModal } = React.useContext(ModalContext.context);

    const renderButtonText = () => {
        if (path === '/background') return 'Next: Research Phases';
        if (path === '/phases') return 'Next: Timeline';
        if (path === '/timeline') return 'Next: Team';
        if (path === '/team') return 'Next: Support Us';
    }

    return (
        <AppHeader>
            <ButtonGroup className="btn-group" variant="contained" onClick={() => dispatchModal({ type: "OPEN", payload: <Pages /> })}>
                <Button className="btn-left">{renderButtonText()}</Button>
                <Button className="btn-right" size="small"><ArrowRightIcon /></Button>
            </ButtonGroup>
        </AppHeader>
    )
}

export default Header;