import React from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from '../helpers';

export const SectionLinks = styled("div", { shouldForwardProp: prop => prop !== 'labelColor' })(({ theme, labelColor }) => ({
    width: '100%', margin: '10px 0', textTransform: "uppercase", letterSpacing: 1, flex: 1, display: 'flex', fontSize: '0.7em', alignItems: 'center', overflowX: 'auto',
    '& .reference-label': { color: !isEmpty(labelColor) ? labelColor : 'inherit', },
    '& a': {
        margin: 5, flexShrink: 0, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', background: theme.palette.primary.main, color: '#FFF', textDecoration: 'none', borderRadius: '50%', fontSize: '0.8em',
        '&:hover': { filter: 'brightness(130%)' },
    },
    '&::-webkit-scrollbar': { height: 7, background: '#EEE', },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main, },
}));

const ReferenceList = ({ references, labelColor }) => {
    if (isEmpty(references)) return null;
    return (
        <SectionLinks labelColor={labelColor}>
            <span className="reference-label">References</span>
            {references.map((reference, index) => {
                return (
                    <Tooltip key={`reference-${index}`} title={reference.title}>
                        <a target="_blank" rel="noreferrer" href={reference.link}>{index + 1}</a>
                    </Tooltip>
                )
            })}
        </SectionLinks>
    )
}

export default ReferenceList;