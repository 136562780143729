import React from "react";
import { Containers, Banners, Steppers } from "../../theme";
import CancerChallenge from './1_CancerChallenge';
import DetectingCancer from './2_DetectingCancer';
import DetectingProblems from './3_DetectingProblems';
import PrecancerPolyps from './4_PreCancerPolyps';
import ScreeningPolyps from './5_ScreeningPolyps';
import CapsuleTechnology from './6_CapsuleTechnology';

const steps = [
    { label: 'The Colon Cancer Challenge', component: <CancerChallenge /> },
    { label: 'How Do We Detect / Screen for Colon Cancer?', component: <DetectingCancer /> },
    { label: 'The Problem with Screening for Colon Cancer', component: <DetectingProblems /> },
    { label: 'Why We Should Screen for Pre-Cancerous Polyps Instead', component: <PrecancerPolyps /> },
    { label: 'Why Current Technology Prevents Us from Screening for Polyps', component: <ScreeningPolyps /> },
    { label: 'How Capsule-Based AI Technology Offers a Potential Solution', component: <CapsuleTechnology /> },
];

const Background = () => {
    const [activeStep, setActiveStep] = React.useState(-1);

    const StepperMethods = {
        handleNext: () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
        handleBack: () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
        handleSelectStep: (newStep) => setActiveStep((prevActiveStep) => (prevActiveStep === newStep) ? -1 : newStep),
        handleReset: () => setActiveStep(-1),
    }

    return (
        <Containers.View>
            <Banners.Mini
                title="Background"
                blurb={`Colon cancer can be eradicated if we can detect and remove precancerous colonic polyps.
                Capsule-based technology could enable this, but we must solve the software, hardware, and human
                factors, which currently prevent its mainstream adoption.`}
            />
            <Steppers.Vertical
                steps={steps}
                activeStep={activeStep}
                Methods={StepperMethods}
            />
        </Containers.View>
    )
}

export default Background;