import React from 'react'
import { Containers, Cards } from "../../theme";

export default function PreCancerPolyps() {
    return (
        <Containers.Cards className="full-width">
            <Cards.Section 
                cardDesc="~95% of colon cancer starts as a benign precancerous growth known as a polyp, which evolves into cancer over a period of ~10 years."
                references={[
                    { title: "Colorectal cancer screening: scientific review", link: "https://pubmed.ncbi.nlm.nih.gov/12633191/" },
                    { title: "Screening for Colorectal Cancer", link: "https://www.acpjournals.org/doi/10.7326/0003-4819-113-5-373" },
                    { title: "Time from positive screening fecal occult blood test to colonoscopy and risk of neoplasia", link: "https://pubmed.ncbi.nlm.nih.gov/19093199/" },
                ]}
            />
            <Cards.Section 
                cardDesc={<>While only 5% of polyps become cancer, removal of polyps is said to <span className="emphasise primary">reduce colon cancer incidence by up to 90%</span>.</>}
                references={[
                    { title: "Prevention of colorectal cancer by colonoscopic polypectomy. The National Polyp Study Workgroup", link: "https://pubmed.ncbi.nlm.nih.gov/8247072/" },
                    { title: "Colonoscopic Polypectomy and Long-Term Prevention of Colorectal-Cancer Deaths", link: "https://www.nejm.org/doi/full/10.1056/nejmoa1100370" },
                    { title: "Efficacy in standard clinical practice of colonoscopic polypectomy in reducing colorectal cancer incidence", link: "https://gut.bmj.com/content/48/6/812" },
                ]}
            />
            <Cards.Section 
                className="emphasise"
                cardDesc="By detecting and removing precancerous colonic polyps most at risk of developing into cancer, it may be possible to eradicate the disease."
            />
        </Containers.Cards>
    )
}