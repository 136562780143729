import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { Containers, Banners, Logos, Buttons } from '../../theme';
import { Favorite as LoveIcon, EmojiEmotions as SmileyIcon } from '@mui/icons-material';
import { ModalContext } from '../../contexts';
import { Dialog, Pages } from '../../components';

function Home() {
    const history = useHistory();
    const { dispatchModal } = React.useContext(ModalContext.context);
    return (
        <Containers.View>
            <Dialog />
            <Logos.Text className="mini" white={true} position="top-left" />
            <Banners.Main>
                <div className="content">
                    <Typography className="logo-text">NΞO</Typography>
                    <Typography className="main-title" variant="h2">A Pill to Prevent Colon Cancer</Typography>
                    <Typography className="sub-title" variant="h5">
                        Colon cancer is the second leading cause of cancer and the second leading cause of cancer related death.
                        Our ambitious research aims to develop an electronic capsule that can safely and easily identify precancerous
                        polyps and flag them for removal before they develop into cancer from the comfort of your own home.
                    </Typography>
                    <Buttons.Dual 
                        buttons={[
                            <Button variant="action" startIcon={<SmileyIcon />} onClick={() => dispatchModal({ type: "OPEN", payload: <Pages /> })}>Learn More</Button>,
                            <Button variant="action" startIcon={<LoveIcon />} onClick={() => history.push('/support')}>Support Our Work</Button>
                        ]}
                    />
                </div>
            </Banners.Main>
        </Containers.View>
    );
}

export default Home;