import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import LogoWhite from '../images/logo-white.svg';
import LogoColored from '../images/logo.svg';

/**************************************************************************************************** 
 * LOGO TEXT
****************************************************************************************************/

const LogoTextWrapper = styled("div", { shouldForwardProp: (prop) => !['white', 'centered', 'sx'].includes(prop) })(({ theme, white, centered, }) => ({
    fontFamily: "'Comfortaa', sans-serif", fontSize: 2.5 * 16, letterSpacing: 2, display: 'flex', alignItems: 'center', justifyContent: centered ? "center" : "flex-start", margin: 20,
    '&.top-left': { position: 'absolute', top: 10, left: 10, zIndex: 999 },
    '& .logo-nexus': { color: white ? '#FFF' : theme.palette.primary.main, },
    '& .logo-icon': { width: 2.5 * 24, margin: '0 10px', },
    '& .logo-health': { color: white ? '#FFF' : theme.palette.secondary.main, },
    [theme.breakpoints.down('sm')]: { fontSize: 1.5 * 16, '& .logo-icon': { width: 1.5 * 24 } },
    '&.mini': { fontSize: 16, cursor: 'pointer', margin: 0, '& .logo-icon': { width: 24, margin: '0 7px' }, },
}));

export const LogoText = ({ white, centered, position, className, ...props }) => {
    return (
        <LogoTextWrapper className={clsx(position, className)} white={white} centered={centered} {...props}>
            <span className="logo-nexus">NΞXUS</span>
            <img className="logo-icon" alt="" src={white ? LogoWhite : LogoColored} />
            <span className="logo-health">HΞЛLTH</span>
        </LogoTextWrapper>
    )
}

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Logos = {
    Text: LogoText,
}

export default Logos;