import React from 'react';
import { styled } from '@mui/material/styles';
import { capitalizeAllFirst } from '../helpers';

export const LegendWrapper = styled("div", { shouldForwardProp: prop => prop !== 'itemCount' })(({theme, itemCount}) => ({
    width: 400, maxWidth: '100%', background: '#FFF', borderRadius: 4, position: 'relative', display: 'flex', margin: '20px 0',
    '& .color': { textAlign: 'center', color: '#FFF', width: `calc(400px / ${itemCount})`, padding: 20, fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center', 
        '&:first-of-type': { borderRadius: '4px 0 0 4px' },
        '&:last-of-type': {borderRadius: '0 4px 4px 0'},
    },
}));

const Legend = ({ legend }) => {
    return (
        <LegendWrapper itemCount={legend.length}>
            {legend.map((item) => {
                return <div key={'legend-item-' + item.label} className="color" style={{ background: item.color }}>{capitalizeAllFirst(item.label)}</div>
            })}
        </LegendWrapper>
    )
}

export default Legend;