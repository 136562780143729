import React from 'react';
import Wavify from 'react-wavify';
import { styled } from '@mui/material/styles';

/**************************************************************************************************** 
 * WAVE ANIMATION
****************************************************************************************************/

const WaveWrapper = styled("div")(({ theme }) => ({
    marginBottom: -10,
}));

const WaveComponent = () => {
    return (
        <WaveWrapper>
            <Wavify
                fill="#040027"
                paused={false}
                options={{ height: 30, amplitude: 30, speed: 0.3, points: 4 }}
            />
        </WaveWrapper>
    )
}

/**************************************************************************************************** 
 * SLIDING SQUARES BACKGROUND ANIMATION
****************************************************************************************************/

const SlidingSquaresWrapper = styled("div", { shouldForwardProp: prop => prop !== 'linearGradient' })(({ theme, height, linearGradient }) => ({
    height: height ? height : '100vh', minHeight: height ? height : '100vh', position: 'fixed', width: '100%', zIndex: -1, top: 0, left: 0, padding: 0, overflow: 'hidden',
    '& .squares': {
        animation: `slidingSquare 1s infinite`, background: linearGradient ? linearGradient : theme.palette.gradient.light, position: 'absolute', transition: '.5s ease-out', overflow: 'hidden', borderRadius: '20%',
        '&.square1': { animation: `slidingSquare 4s infinite`, height: '300px', width: '300px', opacity: '.5', left: '3%', top: '-21%', },
        '&.square2': { animation: `slidingSquare 6s infinite`, height: '400px', width: '400px', opacity: '.4', right: '-5%', top: '-12%', },
        '&.square3': { animation: `slidingSquare 5s infinite`, height: '200px', width: '200px', opacity: '.1', left: '-5%', bottom: '0', },
        '&.square4': { animation: `slidingSquare 10s infinite`, height: '100px', width: '100px', opacity: '.4', right: '27%', top: '70%', },
        '&.square5': { animation: `slidingSquare 6s infinite`, height: '250px', width: '250px', opacity: '.1', left: '32%', bottom: '29%', },
        '&.square6': { animation: `slidingSquare 9s infinite`, height: '80px', width: '80px', opacity: '.5', left: '10%', top: '35%', },
        '&.square7': { animation: `slidingSquare 3s infinite`, height: '300px', width: '300px', opacity: '.1', right: '-5%', bottom: '0', },
    },
    '@keyframes slidingSquare': {
        "0%": { transform: "translateX(-10px)" },
        "50%": { transform: "translateX(10px)" },
        "100%": { transform: "translateX(-10px)" }
    },
}));

const SlidingSquaresAnimation = ({ height, linearGradient, ...props }) => {
    return (
        <SlidingSquaresWrapper height={height} linearGradient={linearGradient} {...props}>
            <div className={"squares square1"}></div>
            <div className={"squares square2"}></div>
            <div className={"squares square3"}></div>
            <div className={"squares square4"}></div>
            <div className={"squares square5"}></div>
            <div className={"squares square6"}></div>
            <div className={"squares square7"}></div>
        </SlidingSquaresWrapper>
    )
}

/*
<div className="blob">
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 350">
        <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z" />
    </svg>
</div>
'& .blob': {
        position: 'absolute', top: 0, left: 0, zIndex: -1, fill: 'rgb(173 53 160 / 50%)', width: '50vh', animation: 'move 10s ease-in-out infinite', transformOrigin: '50% 50%',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    '@keyframes move': {
        "0%": { transform: "scale(1) translate(10px, -30px)" },
        "38%": { transform: "scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)", },
        "40%": { transform: "scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)", },
        "78%": { transform: "scale(1.3) translate(0vw, 50vh) rotate(-20deg)", },
        "80%": { transform: "scale(1.3) translate(0vw, 50vh) rotate(-20deg)", },
        "100%": { transform: "scale(1)   translate(10px, -30px)", },
    }
*/

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Animations = {
    SlidingSquares: SlidingSquaresAnimation,
    Wave: WaveComponent,
}

export default Animations;