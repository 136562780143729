const plan = {
    name: "Development Timeline",
    children: [
        { name: "Phase 1: User Acceptability", nodeType: "phase", number: 1,
            children: [
                { name: "Concept Paper", size: 1, nodeType: "paper", number: 1, },
                { name: "Patient Survey", nodeType: "method", number: 1,
                    children: [
                        { name: "Protocol & Ethics", size: 1, nodeType: "task", number: 1, },
                        { name: "Data Collection", size: 1, nodeType: "task", number: 2, },
                        { name: "Data Analysis", size: 1, nodeType: "task", number: 3, },
                        { name: "Publish Results", size: 1, nodeType: "paper", number: 2, },
                    ]
                },
                { name: "Clinician Interview", nodeType: "method", number: 2,
                    children: [
                        { name: "Protocol & Ethics", size: 1, nodeType: "task", number: 4 },
                        { name: "Data Collection", size: 1, nodeType: "task", number: 5 },
                        { name: "Data Analysis", size: 1, nodeType: "task", number: 6 },
                        { name: "Publish Results", size: 1, nodeType: "paper", number: 3 },
                    ]
                }
            ]
        },
        { name: "Phase 2: Clinician Software", nodeType: "phase", number: 2,
            children: [
                { name: "Interface Development", size: 1, nodeType: "method", number: 3,
                    children: [
                        { name: "Low-Fidelity Wireframe", size: 1, nodeType: "task", number: 7 },
                        { name: "UI / UX Development", size: 1, nodeType: "task", number: 8 },
                        { name: "Initial AI Algorithm Development / Evaluation", size: 1, nodeType: "task", number: 9, },
                        { name: "Evaluation / Feedback of Interface", size: 1, nodeType: "task", number: 10, },
                        { name: "Publish Results", size: 1, nodeType: "paper", number: 4, },
                    ]
                },
                { name: "AI Algorithm Development", size: 1, nodeType: "method", number: 4,
                    children: [
                        { name: "Concept Paper", size: 1, nodeType: "paper", number: 5 },
                        { name: "Develop Real-time Training Module", size: 1, nodeType: "task", number: 11, },
                        { name: "Evaluation of Dynamic System", size: 1, nodeType: "task", number: 12, },
                        { name: "Publish Results of Dynamic AI", size: 1, nodeType: "paper", number: 6 },
                    ]
                },
            ]
        },
        { name: "Phase 3: Patient Hardware", nodeType: "phase", number: 3,
            children: [
                { name: "Patient Hardware Prototyping", size: 1, nodeType: "method", number: 5 },
            ],
        },
        { name: "Phase 4: Patient Software", nodeType: "phase", number: 4,
            children: [
                { name: "Interface Development", size: 1, nodeType: "method", number: 6 },
            ],
        }
    ]
}

export default plan;