import React from 'react';
import { styled } from '@mui/material/styles';
import Sunburst from 'sunburst-chart'; // https://github.com/vasturiano/sunburst-chart
import { Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material';

/**************************************************************************************************** 
 * SUNBURST GRAPH
****************************************************************************************************/

const chart = Sunburst();

const SunburstGraphControlsWrapper = styled(FormControl)(({ theme, }) => ({
    position: 'fixed', bottom: 0, left: 0, width: '100%', alignItems: 'center', justifyContent: 'center', background: '#040027', zIndex: 999, padding: '0 20px',
    '& .MuiTypography-root': { textTransform: "uppercase", letterSpacing: '1px', color: 'rgba(255,255,255,.5)' },
    '& .radio-btn': { color: 'rgba(255,255,255,.5)',
        '& .MuiSvgIcon-root': { fontSize: 40, },  
        '&.Mui-checked': { color: theme.palette.primary.main, 
            '& + .MuiTypography-root': { color: theme.palette.primary.main },
        },
        
    }
}));

const SunburstChartWrapper = styled("div")(({ theme }) => ({
    display: 'flex', justifyContent: 'center', width: '100%',
    '& .sunburst-tooltip': { maxWidth: '100%', whiteSpace: 'normal', },
}));

const SunburstGraphControls = ({ selected, onChange }) => {
    return (
        <SunburstGraphControlsWrapper component="fieldset">
            <RadioGroup row name="position" value={selected} onChange={onChange}>
                <FormControlLabel
                    value="zoom"
                    control={<Radio className="radio-btn" />}
                    label="Zoom"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="select"
                    control={<Radio className="radio-btn" />}
                    label="Select"
                    labelPlacement="end"
                />
            </RadioGroup>
        </SunburstGraphControlsWrapper>
    )
}

const SunburstGraphComponent = ({ data, colors, onClickNode }) => {
    const [selectedRadioValue, setSelectedRadioValue] = React.useState('zoom');
    const sunburstRef = React.useRef(null);

    React.useEffect(() => {
        sunburstRef.current.innerHTML = '';
        chart
            .data(data)
            .excludeRoot(true)
            .width(window.innerWidth - 20)
            .color(d => colors[d.nodeType])
            .label('name')
            .size('size')
            .onClick(Methods.onClick)
            (sunburstRef.current)
        window.addEventListener("resize", Methods.onResize);
        return () => window.removeEventListener("resize", Methods.onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        Methods.updateClickHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRadioValue]);
    
    const Methods = {
        onClick: (node) => {
            if (!node) return;
            if (selectedRadioValue === 'zoom') { chart.focusOnNode(node); } 
            else { onClickNode(node); }
        },
        updateClickHandler: () => chart.onClick(Methods.onClick),
        onResize: () => chart.width(window.innerWidth - 20),
    }

    const onChangeRadioButton = (event) => {
        setSelectedRadioValue(event.target.value);
    };

    return (
        <>
            <SunburstChartWrapper ref={sunburstRef}></SunburstChartWrapper>
            <SunburstGraphControls selected={selectedRadioValue} onChange={onChangeRadioButton} />
        </>
    )
}


/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Graphs = {
    Sunburst: SunburstGraphComponent,
}

export default Graphs;