import React from 'react';
import clsx from 'clsx';
import { Typography, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReferenceList } from '../components';
import { isEmpty } from '../helpers';

/**************************************************************************************************** 
 * SECTION CARD: Cards that go inside a Section Container
****************************************************************************************************/

const SectionCardWrapper = styled("div")(({ theme, background }) => ({
    width: 350, background: '#FFF', padding: 40, borderRadius: 4, margin: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 18, position: 'relative',
    [theme.breakpoints.down('sm')]: { margin: '20px 0', },
    '& .main-text': {
        '& .emphasise': {
            fontWeight: 600,
            '&.primary': { color: theme.palette.primary.main },
            '&.danger': { color: 'red' },
        },
    },
    '&.with-sections': {
        flexDirection: 'column', padding: 0,
        '& .sub-section': {
            padding: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%', color: '#FFF', fontWeight: 500,
            '&:first-of-type': { backgroundColor: theme.palette.primary.main, borderRadius: '4px 4px 0 0', fontSize: '1.5rem' },
            '&:last-of-type': { backgroundColor: theme.palette.secondary.main, borderRadius: '0 0 4px 4px', fontSize: '1.5rem' },
        },
    },
    "&.with-list": {
        flexDirection: 'column', padding: 0,
        '& .blurb': { padding: 30, },
        '& .single-list': { padding: 30, backgroundColor: background === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, fontSize: '1.5rem', borderRadius: '0 0 4px 4px', color: '#FFF', },
        '& .list': {
            backgroundColor: background === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main, height: '100%', color: '#FFF', width: '100%', borderRadius: '0 0 4px 4px',
            '& .list-item': {
                padding: 20, color: 'rgba(255,255,255,.7)', borderBottom: '1px solid rgba(255,255,255,.2)',
                '&:last-of-type': { borderBottom: 'none', }
            },
        },
    },
    '&.with-chip': {
        padding: '60px 40px',
        '& .chip': { position: 'absolute', top: 10, right: 10, background: theme.palette.primary.main, color: '#FFF', fontWeight: 500, },
    },
    '&.emphasise': {
        backgroundColor: theme.palette.primary.main, color: '#FFF', fontWeight: 500,
        '&.secondary': { backgroundColor: theme.palette.secondary.main, },
    }
}));

const SectionCard = ({ cardType, sections, cardDesc, references, className, ...props }) => {

    if (cardType === 'split-sections') return (
        <SectionCardWrapper className={clsx("with-sections", className)} {...props}>
            {sections.map((section, index) => {
                return (<div key={`split-section-${index}`} className="sub-section">{section}</div>)
            })}
        </SectionCardWrapper>
    )

    if (cardType === 'blurb-list') return (
        <SectionCardWrapper className={clsx("with-list", className)} {...props}>
            <div className="blurb">
                {cardDesc}
                {!isEmpty(references) && (
                    <ReferenceList
                        references={references}
                    />
                )}
            </div>
            {sections.length === 1 ?
                <div className="single-list">{sections[0]}</div> :
                <div className="list">
                    {sections.map((section, index) => {
                        return (<div key={`list-section-${index}`} className="list-item">{section}</div>)
                    })}
                </div>
            }
        </SectionCardWrapper>
    )

    return (
        <SectionCardWrapper className={className} {...props}>
            <div className="main-text">{cardDesc}</div>
            {!isEmpty(references) && (
                <ReferenceList
                    references={references}
                />
            )}
        </SectionCardWrapper>
    )
}

/**************************************************************************************************** 
 * BLOB CARD
****************************************************************************************************/

const BlobCardWrapper = styled("div", { shouldForwardProp: prop => prop !== 'cardNumber' })(({ theme, cardNumber }) => ({
    overflow: 'hidden', width: '400px', height: "350px", maxWidth: "100%", margin: '40px', position: 'relative', background: '#FFF', borderRadius: '10px',
    [theme.breakpoints.down('sm')]: { margin: '30px 20px' },
    // '& *': { transition: 'all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', },
    '&:before': { content: '""', position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: 'transparent', zIndex: 200 },
    '& .card-label': {
        overflow: 'hidden', position: 'absolute', width: '100%', height: '100%', left: 0, top: 0,
        '& .MuiTypography-root': { position: 'absolute', top: '30px', right: '30px', zIndex: 9, color: "rgba(255,255,255,.8)", fontSize: '40px', fontWeight: 600, margin: 0, opacity: 1, },
    },
    '& .card-title': {
        zIndex: 99, position: 'absolute', background: '#6d44b5', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', bottom: 0, left: 0, width: '100%', height: '50%', padding: '20px', borderRadius: '0 0 10px 10px',
        '& .MuiTypography-root': { color: 'rgba(255,255,255,.8)', fontWeight: 600, },
    },
    '& .chip-options': { zIndex: 999, position: 'absolute', top: '40%', right: 5,
        '& a': { textDecoration: 'none', },
        '& .chip-option': { cursor: 'pointer', color: '#FFF', background: 'rgba(0,0,0,.1)', margin: 5, fontWeight: 600, border: '2px solid', height: 40, borderRadius: 40, 
            '&:hover': { background: "#FFF", borderColor: '#FFF', color: '#000'}
        },
    },
    '& .corner-icon': {
        position: 'absolute', borderRadius: '10px', width: '50%', height: '100%', zIndex: 0, overflow: 'hidden',
        '& .icon': {
            background: '#fba0cc', position: 'absolute', width: '75%', height: '45%', top: '-12.5%', marginLeft: '-12.5%', transformStyle: 'preserve-3d', borderRadius: '100%', boxShadow: 'inset 0 0 0 5px #FFF, 0 0 0 5px #FBA0CC, 0 0 0 10px #FFF',
            [theme.breakpoints.down('sm')]: { height: '40%', },
            '&:before': { content: `"${cardNumber}"`, fontSize: '75px', position: 'absolute', left: '50%', top: '50%', margin: '20px 0 0 5px', transform: 'translateX(-50%) translateY(-50%)', filter: 'saturate(0) brightness(20)', opacity: 0.25, },
        }
    },
    '& .card-desc': { zIndex: 100, position: 'absolute', display: 'none', color: '#FFF', fontWeight: 500, width: '100%', height: '100%', padding: '20px 30px', fontSize: '16px',transition: 'none', },
    '& .shapes': {
        position: 'absolute', left: '45%', top: '20%', background: ' #fe8d58', width: '60px', height: '4px', zIndex: 999, boxShadow: '0 8px 0 0 #fe8d58, 0 16px 0 0 #fe8d58, 0 24px 0 0 #fe8d58, 0 32px 0 0 #fe8d58', mixBlendMode: 'screen',
        '&:before': { content: '""', position: 'absolute', width: '40px', height: '40px', left: '140px', top: '20px', background: '#fe8d58', clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)', },
    },
    '& .blob': {
        position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, filter: 'url(#goo) opacity(1)',
        '&:before, &:after': { content: '""', position: 'absolute', width: '55%', height: '60%', background: '#6d44b5', borderRadius: '100%', right: 0, top: '20px', transition: 'all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', },
        '&:after': { right: '-15%', top: '20%', width: '40%', height: '55%', },
        '& .glob': { position: 'absolute', width: '60%', height: '75%', background: '#6d44b5', borderRadius: '100%', top: '-25%', right: '-25%', },
    },
    '&.card-drafting': {
        '& .corner-icon': {
            '& .icon': { background: '#b9acca', boxShadow: 'inset 0 0 0 5px #fff, 0 0 0 5px #b9acca, 0 0 0 10px #fff', },
        },
        '& .card-title': { background: '#fe8d58', },
        '& .blob': {
            '&:before': { top: '40%', width: '70%', background: '#fe8d58', },
            '&:after': { background: '#fe8d58' },
            '& .glob': { width: '80%', height: '75%', background: '#fe8d58', }
        },
        '& .shapes': {
            top: '25%', left: '55%', width: '90px',
            '&:before': { left: '80px', top: '100px', clipPath: 'polygon(0 0, 0% 100%, 100% 50%)', },
        }
    },
    '&.card-reviewing': {
        '& .corner-icon': {
            '& .icon': { background: '#b9acca', boxShadow: 'inset 0 0 0 5px #fff, 0 0 0 5px #b9acca, 0 0 0 10px #fff', },
        },
        '& .card-title': { background: '#f44336', },
        '& .blob': {
            '&:before': { top: '20%', background: '#f44336', left: '40%', },
            '&:after': { background: '#f44336', left: '70%', },
            '& .glob': { width: '70%', height: '55%', background: '#f44336', }
        },
        '& .shapes': {
            top: '30%', left: '65%', width: '140px',
            '&:before': { left: '0px', top: '-70px', clipPath: 'polygon(100% 0, 0 0, 100% 100%)', },
        }
    },
    '&.card-completed': {
        '& .corner-icon': {
            '& .icon': { background: '#b9acca', boxShadow: 'inset 0 0 0 5px #fff, 0 0 0 5px #b9acca, 0 0 0 10px #fff', },
        },
        '& .card-title': { background: '#009688', },
        '& .blob': {
            '&:before': { top: '20%', background: '#009688', left: '40%', },
            '&:after': { background: '#009688', left: '70%', },
            '& .glob': { width: '70%', height: '55%', background: '#009688', }
        },
        '& .shapes': {
            top: '30%', left: '65%', width: '140px',
            '&:before': { left: '0px', top: '-70px', clipPath: 'polygon(100% 0, 0 0, 100% 100%)', },
        }
    },
    '&.active': {
        overflow: 'unset', filter: 'none',
        '& .shapes': { zIndex: -1, },
        '& .card-title': { display: 'none' },
        '& .chip-options': { top: 'unset', bottom: 0,
            '& .chip-option:first-of-type': { background: '#FFF', color: '#000', borderColor: '#FFF', },
        },
        '& .card-desc': { overflow: 'auto', display: 'block', transition: 'all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.175)', transitionDelay: '0.2s', },
        '& .blob': { zIndex: 99,
            // zIndex: 99, transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            // '&:before, &:after': { transform: 'scale(0)', },
            '&:after': { display: 'none' },
            '& .glob': { minWidth: '110%', minHeight: '110%', borderRadius: '0px', top: '-5%', right: '-5%' },
        },
    },
}))

const BlobCard = ({ cardNumber, cardState, cardLabel, title, cardDesc, link }) => {
    const [activeCard, setActiveCard] = React.useState(false);
    return (
        <BlobCardWrapper className={clsx("card", `card-${cardState}`, { active: activeCard })} cardNumber={cardNumber}>
            <div className="card-label">
                <Typography variant="h6">{cardLabel}</Typography>
            </div>
            <div className="chip-options">
                <Chip className="chip-option" label={activeCard ? "Back" : "Description"} onClick={() => setActiveCard(prev => !prev)} />
                {!isEmpty(link) && <a target="_blank" rel="noreferrer" href={link}><Chip className="chip-option" label="Go to Link" /></a>}
            </div>
            <div className="card-title">
                <Typography variant="h5">{title}</Typography>
            </div>
            <p className="card-desc">{cardDesc}</p>
            <div className="shapes"></div>
            <div className="corner-icon">
                <div className="icon"></div>
            </div>
            <div className="blob"><div className="glob"></div></div>
            {/* <svg className="svg" viewBox="0 0 400 400">
                <defs>
                    <filter id="duotone-filter-post-one">
                        <feColorMatrix type="matrix" values="0.14453125 0 0 0 0.33203125 0.71875 0 0 0 0.27734375 -0.34765625 0 0 0 0.73046875 0 0 0 1 0"></feColorMatrix>
                    </filter>
                </defs>
            </svg> */}
        </BlobCardWrapper>
    )
}

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/
const Cards = {
    Blob: BlobCard,
    Section: SectionCard
}

export default Cards;
