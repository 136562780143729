import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Favorite as LoveIcon, } from '@mui/icons-material';
import { renderNumber } from '../helpers';
import { ModalContext } from '../contexts';

const pages = [
    { title: 'Background', route: '/background' },
    { title: 'Development Phases', route: '/phases' },
    { title: 'Development Timeline', route: '/timeline' },
    { title: 'Publications & Plans', route: '/papers' },
    { title: 'Team & Collaborators', route: '/team' },
    { title: 'Support Our Work', route: '/support' },
];

export default function Pages() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { dispatchModal } = React.useContext(ModalContext.context);

    const changePage = (route) => {
        history.push(route);
        dispatchModal({ type: "CLOSE" });
    }
    
    return (
        <Box sx={{ width: 600, maxWidth: '100%', }}>
            <nav aria-label="main mailbox folders">
                <List>
                    {pages.map((page, index) => {
                        return (
                            <ListItem key={`page-route-${page.route}-${index}`} disablePadding>
                                <ListItemButton onClick={() => changePage(page.route)} selected={path === page.route}>
                                    <ListItemIcon>{page.route === '/support' ? <LoveIcon /> : renderNumber(index + 1)}</ListItemIcon>
                                    <ListItemText primary={page.title} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </nav>
        </Box>
    )
}