const phases = {
    1: {
        year: "2021",
        title: "Phase 1: Usability & Acceptability by Patients & Clinicians",
        aim: "The aim of phase 1 is to unpack patient and clinician attitudes and concerns towards intelligent capsule-based technology and identify the human factors that the underlying technology must address to maximise acceptability, adoption, and adherence.",
        questions: [
            {
                primary: "What are patient attitudes towards capsule-based technology?", 
                secondary: "Are patients willing to accept swallowing an electronic capsule for the purpose of colon cancer prevention?" 
            },
            {
                primary: "Capsule based technology requires extensive bowel preparation. How does this affect patient willingness to embrace the technology?", 
                secondary: "~30% of patients have insufficient bowel prep for the procedure. To what extent does this impact the utility of the technology?" 
            },
            {
                primary: "How do patients feel about screening that is performed predominantly by an AI detection method?", 
                secondary: "Do patients trust AI technology and how do we maximise trust in end-users?" 
            },
            {
                primary: "Given that users are non-homogenous, what are the needs of different patient groups, so that the technology is equitable?", 
                secondary: "How do we account for the varied socio-contextual factors (e.g. technical literacy, medical literacy, access, awareness, remoteness?" 
            },
            {
                primary: "What user interaction factors need to be re-engineered to maximise patient adoption?", 
                secondary: "e.g. Current generation devices require that patients wear a sensor belt around the waist - is this a problem?" 
            },
            {
                primary: "What are clinician perceptions of an at-home capsule-based technology?", 
                secondary: "What concerns do clinicians have? Are clinicians willing to integrate their clinical workflow into the workflow of an at-home diagnostic device?" 
            },
        ],
        references: [
            {
                title: "Feasibility of a colon capsule overnight procedure followed by colonoscopy", 
                link: "https://pubmed.ncbi.nlm.nih.gov/26854834/" 
            },
            {
                title: "Colon capsule endoscopy: European Society of Gastrointestinal Endoscopy (ESGE) Guideline", 
                link: "https://pubmed.ncbi.nlm.nih.gov/22389230/" 
            },
            {
                title: "Potential Screening Benefit of a Colorectal Imaging Capsule That Does Not Require Bowel Preparation", 
                link: "https://journals.lww.com/jcge/fulltext/2014/01000/potential_screening_benefit_of_a_colorectal.12.aspx" 
            }
        ]
    },
    2: {
        year: "2022",
        title: "AI System for Data Capture and Analysis for Clinicians",
        aim: "The aim of phase 2 is to develop a human-in-the-loop iterative AI learning algorithm whose intelligence increases over time. We will also design and develop the software interface that clinicians will use to work with the AI.",
        questions: [
            { 
                primary: "Current capsule technology requires ~8 hours of video footage to be analysed by a professional trained video reader - a limited resource. Can we develop an adaptive AI system that is suitable for the real-world hetereogeneity of healthcare?", 
                secondary: "How should an adaptive algorithm work and how can we be confident that an iteratively trained model is safe when productionised?",
            },
            {
                primary: "AI is as good as the data that is used to train it. Clinicians can be wrong. How does this impact the efficacy of the AI and how do we limit the impact of bias?",
                secondary: "Is there a validation approach that is more suitable for healthcare AI?",
            },
            {
                primary: "How does a clinician interface with an automated system in the absence of a comprehensive clinical history that often guides diagnosis?",
                secondary: "Can a clinician diagnose in an epistemological vaccuum in the absence of additional clinical data about the patient? Is video footage sufficient?",
            },
        ],
        references: [
            { 
                title: "Colon Capsule Endoscopy: Review and Perspectives", 
                link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5028851/#B34" 
            },
            { 
                title: "Artificial Intelligence in Capsule Endoscopy: A Practical Guide to Its Past and Future Challenges", 
                link: "https://pubmed.ncbi.nlm.nih.gov/34574063/" 
            },
            { 
                title: "Gastroenterologist-Level Identification of Small-Bowel Diseases and Normal Variants by Capsule Endoscopy Using a Deep-Learning Mode", 
                link: "https://pubmed.ncbi.nlm.nih.gov/31251929/" 
            },
            { 
                title: "Everyone wants to do the model work, not the data work: Data Cascades in High-Stakes AI", 
                link: "https://research.google/pubs/pub49953/" 
            },
        ]
    },
    3: {
        year: "2023-24",
        title: "Capsule Hardware Design for Patients",
        aim: "The aim of phase 3 is to advance the underlying limitations of current-generation capsule-based hardware so that it meets the needs of clinicians and patients alike, maximising user experience and diagnostic capability.",
        questions: [
            {
                primary: "There is no locomotion on current capsules. Movement is purely dependent on the nature of bowel movement (peristalsis). New devices use magnetic control but these are not suitable to be used at home. Is there a way to bring about intelligent locomotion?",
                secondary: "Since battery power is a limitation, can we trigger locomotion specifically in circumstances where we detect a polyp via AI?",
            },
            {
                primary: "Polyps can be hidden within the haustra folds. Conventional colonoscopies improve their visualisation through insufflate of the colon. Is this possible via a capsule?",
            },
            {
                primary: "When bowel prep is insufficient, conventional colonoscopies can still remove debris to improve visualisation. Is this important / possible in a capsule?",
                secondary: "How often do debris hinder visualisation in capsule based technology?"
            },
            {
                primary: "Capsule-based technology is currently a static technology that passively sends images to a video reader. Can we bring on-board intelligence to dynamically increase the frame-rate / image resolution in real-time when polyps are detected?",
                secondary: "How important is the visualisation of the polyp for clinicians?"
            },
            {
                primary: "Visualisation of polyps is enhanced by multi-spectral imaging in conventional colonoscopies. Can we incorporate this into a capsule?"
            },
            {
                primary: "Battery power is a major constraint. ~15% of capsules stop transmitting data before the transit time is over due to slow peristalsis and limited battery life (~10 hours).",
                secondary: "Is it possible to incorporate energy capturing technologies into the capsule design?",
            },
            {
                primary: "Patients must wear a sensor belt around their waist that contains a receiver for 6-8 hours, and this receiver must be returned for analysis. Can the receiver be incorporated into a better form factor?",
                secondary: "Is it possible to move the receiver into a wearable watch / mobile device?"
            },
            {
                primary: "The cost of current capsule technology is prohibitive. Can we bring the price point down to a point that is comparable to current screening technology.",
                secondary: "Is there an economic model that can justify a higher price point, if colon cancer prevention is achieved?"
            },
            {
                primary: "How does a clinician interface with an automated system in the absence of a comprehensive clinical history that often guides diagnosis?",
                secondary: "Can a clinician diagnose in an epistemological vaccuum in the absence of additional clinical data about the patient? Is video footage sufficient?"
            }
        ],
        references: [
            { 
                title: "A Review of Locomotion Systems for Capsule Endoscopy", 
                link: "https://pubmed.ncbi.nlm.nih.gov/26292162/" 
            },
            { 
                title: "A New Active Locomotion Capsule Endoscopy under Magnetic Control and Automated Reading Program", 
                link: "https://pubmed.ncbi.nlm.nih.gov/32746536/" 
            },
            { 
                title: "Feasibility proof of a legged locomotion capsule for the GI tract", 
                link: "https://pubmed.ncbi.nlm.nih.gov/18513557/" 
            },
            { 
                title: "Analysis of current and future technologies of capsule endoscopy: A mini review", 
                link: "https://www.peertechzpublications.com/articles/APM-5-116.php" 
            },
        ]
    },
    4: {
        year: "2025",
        title: "Software Design & UX for Patients Accessing Results",
        aim: "The aim of phase 4 is to develop the user interface and user experience that maximises continuity between the patient, the device, the clinician, and the healthcare system.",
        questions: [
            { 
                primary: "How do we communicate medical processes in consumer language, when distributing a device initially designed for a clinical setting, and repositioning it into the home?" 
            },
            {
                primary: "How does an at-home capsule device communicate its results to the patient without increasing patient anxiety?",
                secondary: "Is it a notification? Is it a text message? What happens if the result is positive? Who becomes the healthcare provider?"
            },
            {
                primary: "Who has access to the capsule and who distributes it?",
                secondary: "Is the device delivered to patients? Do patients purchase it? Does it require a physician referral? Is it disseminated through a pharmacy?"
            }
        ],
        references: [],
    }
}

export default phases;