import React from 'react';
import { Containers, Accordions, Cards } from "../../theme";

export default function DetectingProblems() {
    return (
        <Containers.Section>
            <Accordions.Main title="1. Accuracy Problem">
                <Containers.Cards className="full-width">
                    <Cards.Section
                        cardType="split-sections"
                        sections={["Sensitivity 53% - 100%", "Specificity 85 - 100%"]}
                    />
                    <Cards.Section
                        cardType="blurb-list"
                        cardDesc="The iFOBT is not a diagnostic test and relies on an invasive follow-up colonoscopy. Risks include:"
                        sections={["1 in 1000 Bowel Perforation", "3 in 1000 Haemorrhage", "1-3 in 10,000 Death", "Infection transmission"]}
                    />
                    <Cards.Section
                        cardType="blurb-list"
                        cardDesc="Only 1 in 26 people with a positive iFOBT are subsequently diagnosed with cancer."
                        sections={["False Positives are common resulting in unnecessary colonoscopies."]}
                    />
                </Containers.Cards>
            </Accordions.Main>
            <Accordions.Main title="2. Participation Problem">
                <Containers.Cards className="full-width">
                    <Cards.Section 
                        cardDesc="The iFOBT is offered to people aged 50 - 74 free of charge through the National Bowel Cancer Screening Program in Australia biennially."
                    />
                    <Cards.Section 
                        cardDesc="Yet, participation rates have plateaued at 30-40% for a decade, despite public health campaigns to increase participation."
                        references={[
                            { title: "Factors influencing participation in fecal occult blood testing to screen for colorectal cancer in Australia", link: "https://pubmed.ncbi.nlm.nih.gov/29324557/" }
                        ]}
                    />
                    <Cards.Section 
                        cardDesc="People with the highest risk of colon cancer (e.g. lower socio-economic backgrounds / people of social disadvantage), have the lowest rate of participation in Colon Cancer Screening."
                        references={[
                            { title: "Increasing bowel cancer screening participation: integrating population-wide, primary care and more targeted approaches", link: "https://pubmed.ncbi.nlm.nih.gov/31384890/" },
                            { title: "Varied bowel cancer screening rates across socioeconomic areas", link: "https://www1.racgp.org.au/newsgp/clinical/varied-bowel-cancer-screening-rates-across-socioec" },
                        ]}
                    />
                    <Cards.Section
                        className="emphasise"
                        cardDesc="At the current participation rate of ~40%, it is estimated that iFOBT screening reduces colon cancer-related mortality by ~30%."
                    />
                    <Cards.Section
                        className="emphasise secondary"
                        cardDesc="If the participation rate increased to ~70%, it is estimated that colon cancer-related mortality would reduce by ~59%."
                        references={[
                            { title: "Long-term evaluation of benefits, harms, and cost-effectiveness of the National Bowel Cancer Screening Program in Australia: a modelling study", link: "https://pubmed.ncbi.nlm.nih.gov/29253458/" }
                        ]}
                    />
                    <Cards.Section
                        className="emphasise"
                        cardDesc="Therefore, increasing the participation rate in the current screening program is a national priority."
                    />
                </Containers.Cards>
            </Accordions.Main>
        </Containers.Section>
    )
}
