const papers = {
    1: {
        title: "A Case for Pre-Screening Using Capsule Based Technology: Can We Reduce Colon Cancer Incidence?",
        state: "drafting",
        aim: "This paper will be an opinion piece articulating the view that pre-screening for colonic polyps via capsule-based technology may offer a solution to perturbing colon cancer mortality rates, by mitigating its incidence through the identification and removal of CRC precursor lesions that are most at risk of becoming cancer.",
        objectives: [
            "To establish an argument for why another modality focused on preventative screening should be established",
            "To articulate the view that we are not displacing the FOBT, but creating a tool more sensitive to polyp detection",
        ],
        link: "https://docs.google.com/document/d/1mLKMAOWuvZf7K3n6zVs9R4GtmusOYaaEjSDSZ237720/edit?usp=sharing",
    },
    2: {
        title: "Patient Perceptions of Capsule Based Technology for Preventative Screening",
        state: "scheduled",
        aim: "After surveying patients in a primary-care setting over 3 months, patient preferences for colon cancer screening",
        objectives: [
            "Is there patient acceptability for capsule-based technology, if the objective was framed around preventative screening",
            "Test the hypothesis that patient acceptability for a test aimed at preventing cancer is more desirable than a test aimed at detecting cancer",
            "Understand factors / concerns that might influence patient adherance to capsule-based technology",
            "Understand attitudes of existing screening modalities"
        ]
    },
    3: {
        title: "Clinician Perceptions of Capsule Based Technology for Preventative Screening",
        state: "scheduled",
        aim: "After interviewing 5 gastroenterologists, we want to understand clinician attitudes and concerns around capsule based technology for preventative screening",
        objectives: [
            "Do clinicians see merit in the idea of focusing on preventative screening?",
            "Will clinicians be willing to interface with a capsule-based platform?",
            "What are their technological concerns?",
            "Will they be willing to interface with a platform, if the device was designed to be used at home, with no clinical history of the patient?"
        ]
    },
    4: {
        title: "AI for Clinicians, Co-Designed with Clinicians: Integrating AI into the Clinical Workflow for Capsule-Based Polyp Screening",
        state: "scheduled",
        aim: `This paper will detail the framework adopted and the lessons learnt through the development of an AI powered clinical interface designed for clinicians to assess and evaluate polyps acquired from video feeds acquired 
        from the PillCam2. It will discuss how the interface is as important as the AI model in healthcare settings, while introducing one of the major challenges for future work, which is the need for 
        a dynamic AI system that is suitable for healthcare.`,
        objectives: [
            "To establish an interface that is agnostic to any capsule system that clinicians are willing to engage with",
            "To establish the view that a bottleneck in capsule-based technology for preventative screening will be the reading time",
            "To highlight how an interface designed by clinicians has higher likelihood to be adopted when hardware constraints in capsule technology are addressed",
            "To highlight the importance of participatory design principles in healthcare software development",
            "To share insights on best practices based on a real-world experience of developing the software interface",
            "To propose a methodological framework for AI System Development in Healthcare",
            "To highlight the importance of correctly framing clinical questions in AI System Development",
            "To emphasise that the interface is as important as the model",
        ],
    },
    5: {
        title: "The Interrelationship Between Data, the Model, and the Interface in Medical AI",
        state: "scheduled",
        aim: `This is an opinion piece arguing how one of the fundamental problems with AI systems in Healthcare is that the efficacy of the system 
            is highly influenced by the nature of the training data. Incorrect data labelling, different imaging vendors, image artefacts, among others, all influence the efficacy 
            of the AI models. There is little transparency over the way the data was labelled and the implicit biases that exist by validating models on an unseen subset of data from 
            only a small selection of institutions. Claims of high performance in one healthcare setting do not translate to other healthcare settings and this has been a well documented 
            problem of AI systems. Furthermore, since AI systems have the potential ability for iterative real-time training, whether the new model has the same efficacy as the initially 
            trained model is often unknown. In the rush to productionise AI systems in the hyper-capitalist AI-arms race, a protocol for AI in Healthcare is urgently needed. An opportunity 
            to develop a framework for developing AI for Healthcare Applications exists in this project; given that a critical component towards productionising pre-screening for colon cancer 
            through capsule-based technology in a way that is not cost-prohibitive, will involve the automation (or at least, semi-automation) of polyp detection. Even though companies such as 
            Medtronic claim that their models detect polyps with 99% accuracy and other independent researchers have shown highly efficacious results - what happens when the type of camera 
            changes or the institution using the model changes? What happens to those AI systems?  This paper will
            discuss these issues and present a technical algorithm for dynamic model training that improves in real-time, while also self-validating, and while working in harmony with a
            clinician.`,
        objectives: [
            "To discuss the interrelationship between the data, the model, and the clinical interface",
            "To highlight the limitations of static AI systems in production healthcare deployments",
            "To discuss the need for a dynamic AI system that learns in the real-world",
            "To develop a dynamic AI system suitable for healthcare settings",
            "To develop an AI algorithm that improves in real-time, but validates itself against previous data",
            "To develop an AI validation approach that is suitable for healthcare",
        ]
    },
    6: {
        title: "A Dynamic Real-time Iterative Learning Algorithm for AI in Healthcare",
        state: "scheduled",
        aim: `This paper will be a continution of the themes outlined in paper 5, except will propose a technical solution to the need for dynamic modals
            in healthcare that improves in real-time, while also self-validating.`,
        objectives: [
            "To discuss the interrelationship between the data, the model, and the clinical interface",
            "To highlight the limitations of static AI systems in production healthcare deployments",
            "To discuss the need for a dynamic AI system that learns in the real-world",
            "To develop a dynamic AI system suitable for healthcare settings",
            "To develop an AI algorithm that improves in real-time, but validates itself against previous data",
            "To develop an AI validation approach that is suitable for healthcare",
        ]
    },
}

export default papers;