import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import { Logos } from '../theme';
import { IconButton } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { ModalContext } from '../contexts';
import { Dialog, Pages } from '../components';

const IconButtonStyle = { 
    position: 'fixed', bottom: 15, right: 10, zIndex: 9999, width: '60px', height: '60px', backgroundColor: '#FFF', color: 'other.lavender', 
    '& .MuiSvgIcon-root': { width: '1.3em', height: '1.3em', },  
    '&:hover': { background: '#FFF', color: 'other.lavender' }, 
}

export default function RouteWrapper({ component: Component, ...rest }) {
    const {dispatchModal} = React.useContext(ModalContext.context);
    const history = useHistory();
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <React.Fragment>
                        <Logos.Text className="mini" position="top-left" white={true} onClick={() => history.push('/') } />
                        <IconButton size="large" sx={IconButtonStyle} onClick={() => dispatchModal({ type: "OPEN", payload: <Pages /> })}><ChevronRightIcon /></IconButton>
                        <Dialog />
                        <Component {...props} />
                    </React.Fragment>
                    
                )
            }}
        />
    )
}