import React from 'react';
import { Box, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { LooksOne as Looks1, LooksTwo as Looks2, Looks3, Looks4, Looks5, Looks6, Star as StarIcon, } from '@mui/icons-material';

/**************************************************************************************************** 
 * STEPPER VERTICAL
****************************************************************************************************/

const StepperWrapper = styled(Box)(({theme}) => ({
    zIndex: 999, margin: "0px auto 100px", maxWidth: '80%', padding: '0 25px', '& .MuiStepLabel-label': { fontSize: '2rem' },
    [theme.breakpoints.down('md')]: { maxWidth: '100%', padding: '0 10px', '& .MuiStepLabel-label': { fontSize: '1.5rem' }, },
}));

const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.root}`]: { marginLeft: 24 },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: { background: theme.palette.primary.main, },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: { background: theme.palette.primary.main, },
    },
    [`& .${stepConnectorClasses.line}`]: { width: 3, border: 'none', backgroundColor: 'rgba(255,255,255,.5)', borderRadius: 1, },
}));

const StepperIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: 'rgba(255,255,255,.3)', zIndex: 1, fontWeight: 600, cursor: 'pointer',
    color: '#fff', width: 50, height: 50, display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center',
    ...(ownerState.active && { background: '#FFF', color: theme.palette.primary.main, boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)', }),
    ...(ownerState.completed && { background: theme.palette.primary.main, }),
}));

const StepIcon = (props) => {
    const { active, completed, className } = props;
    const Icons = {
        1: <Looks1 />,
        2: <Looks2 />,
        3: <Looks3 />,
        4: <Looks4 />,
        5: <Looks5 />,
        6: <Looks6 />,
        7: <StarIcon />,
    }
    return (
        <StepperIconRoot ownerState={{ completed, active }} className={className}>
            {Icons[String(props.icon)]}
        </StepperIconRoot>
    );
}

const StepperMainVertical = ({ steps, activeStep, Methods }) => {
    return (
        <StepperWrapper>
            <Stepper activeStep={activeStep} orientation="vertical" connector={<StepperConnector />}>
                {steps.map((step, index) => (
                    <Step key={'step-label-' + index}>
                        <StepLabel StepIconComponent={StepIcon} onClick={() => Methods.handleSelectStep(index)}>
                            {step.label}
                        </StepLabel>
                        <StepContent sx={{ position: 'relative' }}>
                            {step.component}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </StepperWrapper>
    )
}

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Steppers = {
    Vertical: StepperMainVertical,
}

export default Steppers;