const tasks = {
    1: { 
        title: "Developing a Protocol and Submitting Ethics for Patient Survey", 
        description: "We will write and publish a protocol, questions, and ensure that we are in compliance with ethics guidelines associated with a patient survey.",
    },
    2: { 
        title: "Participant Recruitment and Data Collection", 
        description: "We will recruit participants and invite them to complete a survey",
    },
    3: { 
        title: "Data Analysis and Results", 
        description: "We will study and interpret the results of the survey using statistical methods and publish our findings.",
    },
    4: { 
        title: "Developing a Protocol and Submitting Ethics for Clinician Interviews", 
        description: "We will write and publish a protocol for a semi-structured interview with clinicians, and ensure that we are in compliance with ethics guidelines",
    },
    5: { 
        title: "Clinician Recruitment and Data Collection", 
        description: "We will recruit gastroenterologists and invite them to be interviewed",
    },
    6: { 
        title: "Data Analysis and Results", 
        description: "We will analyse the results using a thematic analysis approach and identify attitudes of clinicians towards capsule-based technology for the prevention of colon cancer.",
    },
    7: { 
        title: "Develop a Wireframe for an AI-Based Viewer for Capsule Endoscopies", 
        description: "We will conceptualise an initial low-fidelity wireframe through workshops with clinicians",
    },
    8: { 
        title: "Develop a functional MVP for the Clinical Interface", 
        description: "We will develop a functional minimally viable product so that clinicians can interact with a product concept that emerges out of the workshop",
    },
    9: { 
        title: "Develop a Preliminary AI Module", 
        description: "The purpose of the AI Model is not to maximise accuracy, but rather, to incorporate its output into an interface with a feedback loop. Improving the model will be part of future work.",
    },
    10: { 
        title: "Evaluate the Interface in Terms of Clinician Satisfaction", 
        description: "We want to evaluate the efficacy of the system co-designed for clinicians by introducing it to clinicians without knowledge of the system and explore their experience with it.",
    },
    11: { 
        title: "Develop a Proposal for a Dynamic AI Module", 
        description: "Here we will move away from our initial static AI model and incorporate an algorithm that is dynamic that is suitable for the healthcare domain.",
    },
    12: { 
        title: "Evaluate the Dynamic System in the Real-World", 
        description: "We will evaluate the dynamic learning algorithm in a production setting and publish our findings.",
    },
}

export default tasks;