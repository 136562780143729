import React from 'react';
import { timeline, colors } from '../../data';
import { Banners, Containers, Graphs } from '../../theme';
import { ModalContext } from '../../contexts';
import { Legend } from '../../components';
import DialogTypes from './DialogTypes';

const legend = [
    { color: colors.phase, label: "Phase" },
    { color: colors.method, label: "Method" },
    { color: colors.task, label: "Task" },
    { color: colors.paper, label: "Paper" },
];

const Timeline = () => {
    const { dispatchModal } = React.useContext(ModalContext.context);

    const onClickChartComponents = (node) => {
        if (node.nodeType === "phase") dispatchModal({ type: "OPEN", payload: <DialogTypes.Phase number={node.number} /> });
        if (node.nodeType === "method") dispatchModal({ type: "OPEN", payload: <DialogTypes.Method number={node.number} /> });
        if (node.nodeType === "task") dispatchModal({ type: "OPEN", payload: <DialogTypes.Task number={node.number} /> });
        if (node.nodeType === "paper") dispatchModal({ type: "OPEN", payload: <DialogTypes.Paper number={node.number} /> });
    }

    return (
        <Containers.View sx={{ paddingBottom: '120px' }}>
            <Banners.Mini
                className="timeline-page"
                title="Development Timeline"
                blurb={<React.Fragment>The interactive Sunburst Graph below details the activities we will undertake over the next 5 years to bring the Neo
                    Platform to market. Choose a mode to interact with the graph below. The <span className="emphasise primary">Select Mode</span> will
                    allow you to view details of an activity, while the <span className="emphasise primary">Zoom Mode</span> will focus the circle
                    to the region you have selected.</React.Fragment>}
                additionalComponent={<Legend legend={legend} />}
            />
            <Graphs.Sunburst data={timeline} colors={colors} onClickNode={onClickChartComponents} />
        </Containers.View>
    )
}

export default Timeline;