import React from 'react';
import { styled } from '@mui/material/styles';
import { RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material'; 
import { capitalizeFirst } from '../helpers';

/**************************************************************************************************** 
 * RADIO BUTTONS: NORMAL
****************************************************************************************************/

const RadioButtonsWrapper = styled(FormControl)(({ theme, }) => ({
    marginTop: '20px',
    '& .MuiTypography-root': { textTransform: "uppercase", letterSpacing: '1px', color: 'rgba(255,255,255,.5)' },
    '& .radio-btn': { color: 'rgba(255,255,255,.5)',
        '& .MuiSvgIcon-root': { fontSize: 40, },  
        '&.Mui-checked': { color: theme.palette.primary.main, 
            '& + .MuiTypography-root': { color: theme.palette.primary.main },
        },
        
    }
}));

const RadioButtonsComponent = ({ selected, onChange, options }) => {
    return (
        <RadioButtonsWrapper component="fieldset">
            <RadioGroup row name="position" value={selected} onChange={onChange}>
                {options.map((option,index) => {
                    return (
                        <FormControlLabel
                            key={`radio-btn-key-` + option.value}
                            value={option.value}
                            control={<Radio className="radio-btn" />}
                            label={option.label ? option.label : capitalizeFirst(option.value)}
                            labelPlacement="end"
                        />
                    )
                })}
            </RadioGroup>
        </RadioButtonsWrapper>
    )
}

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Radios = {
    Main: RadioButtonsComponent
}

export default Radios;