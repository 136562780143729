import React from 'react';
import { Containers, Cards } from "../../theme";

export default function CapsuleTechnology() {
    return (

        <Containers.Cards className="full-width">
            <Cards.Section 
                cardDesc={<> Capsule based technology aims to bring the power of a Gold Standard colonoscopy into a miniature electronic 
                capsule that patients can swallow, providing clinicians with a video-feed of the colon.</>}
            />
            <Cards.Section 
                cardDesc={<>The best capsule on the market at the moment, is the Medtronic PillCam2, which is said to have a
                    sensitivity of &gt;85% for polyps over 6mm and &gt;90% for polyps over 10mm.</>}
                references={[
                    { title: "Accuracy of First- and Second-Generation Colon Capsules in Endoscopic Detection of Colorectal Polyps: A Systematic Review and Meta-analysis", link: "https://pubmed.ncbi.nlm.nih.gov/27165469/" }
                ]}
            />
            <Cards.Section 
                className="emphasise"
                cardDesc={<>While capsule-based technology appears to be promising, it is not yet considered a viabile tool for the screening 
                    of precancerous polyps, and is only used for patients who cannot complete a colonoscopy. We want to advance
                    and bring this technology into the mainstream.</>}
            />
        </Containers.Cards>
    )
}