import React from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
export { default as Logos } from './Logos';
export { default as Banners } from './Banners';
export { default as Containers } from './Containers';
export { default as Animations } from './Animations';
export { default as Cards } from './Cards';
export { default as Timelines } from './Timelines';
export { default as Steppers } from './Steppers';
export { default as Accordions } from './Accordions';
export { default as Buttons } from './Buttons';
export { default as Forms } from './Forms';
export { default as Graphs } from './Graphs';
export { default as Radios } from './Radios';
export { default as SVGFilters } from './SVGFilters';

/**************************************************************************************************** 
 * PALETTE
****************************************************************************************************/

const defaultTheme = createTheme({
    palette: {
        background: { main: '#040027', },
        gradient: { light: "linear-gradient(120deg, #ad35a0, #4d7ae8)", dark: 'linear-gradient(110deg, #050037, #10006c, #350888)' },
        primary: { main: '#ad35a0', }, // pink
        secondary: { main: '#4d7ae8', }, // blue
        other: { lavender: '#7769c4', } // Purple
    },
});

/**************************************************************************************************** 
 * COMPONENT STYLE OVERRIDES
****************************************************************************************************/

const theme = responsiveFontSizes(createTheme({
    palette: {
        background: { ...defaultTheme.palette.background },
        gradient: { ...defaultTheme.palette.gradient },
        primary: { ...defaultTheme.palette.primary },
        secondary: { ...defaultTheme.palette.secondary, },
        other: { ...defaultTheme.palette.other, },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: { background: "#350888", },
                '.grecaptcha-badge': { visibility: 'hidden !important', },
            }
        },
        MuiTabs: {
            styleOverrides: {
                scrollButtons: { color: '#FFF', }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: { fontSize: '1.1rem', },
                textColorPrimary: { color: "#FFF", },
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'action' },
                    style: {
                        height: 50, width: 250, margin: '0 auto', paddingLeft: 25, paddingRight: 25, borderRadius: 50, background: '#FFF', color: '#7769c4',
                        '&:hover, &.active': { background: 'rgba(0,0,0,.4)', color: '#FFF' },
                    }
                },
                {
                    props: { variant: 'sticky-action' },
                    style: {
                        zIndex: 999, position: 'sticky', float: 'right', bottom: 20, marginRight: 15, marginTop: 15, height: 50, borderRadius: 50, paddingLeft: 25, paddingRight: 25, background: defaultTheme.palette.primary.main, color: '#FFF',
                        '&:hover, &.active': { background: '#FFF', color: '#000' },
                    }
                }
            ]
        },
        MuiStepLabel: {
            styleOverrides: {
                root: { cursor: 'pointer', },
                label: {
                    color: 'rgba(255,255,255,.5)', letterSpacing: 1, marginLeft: 10, cursor: 'pointer',
                    '&:hover': { filter: 'brightness(150%)', color: '#FFF', },
                    '&.Mui-completed': { color: defaultTheme.palette.primary.main, },
                    '&.Mui-active': { color: '#FFF', },
                },
            }
        },
        MuiStepContent: {
            styleOverrides: {
                root: { borderLeftWidth: 3, borderColor: 'rgba(255,255,255,.5)', marginLeft: 24, }
            }
        }
    }
}));

/**************************************************************************************************** 
 * THEME PROVIDER
****************************************************************************************************/

export function MuiThemeProvider({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}