import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { ReferenceList } from '.';
import { isEmpty, renderNumber, renderNumberFilter } from '../helpers';

export default function ResearchQuestions({ questions, references }) {
    return (
        <List sx={{ background: '#FFF', borderRadius: 4, margin: '20px 0', fontWeight: 400, }}>
            {questions.map((question, index) => {
                return (
                    <React.Fragment key={`question-${index}`}>
                        <ListItem>
                            <ListItemIcon>{renderNumberFilter(index + 1)}</ListItemIcon>
                            <ListItemText primary={question.primary} secondary={question.secondary} />
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                )
            })}
            {!isEmpty(references) && (
                <ListItem>
                    <ListItemIcon>{renderNumber(7)}</ListItemIcon>
                    <ListItemText primary={<ReferenceList references={references} />} />
                </ListItem>
            )}
        </List>
    )
}