import React from 'react';
import { LooksOne as Looks1, LooksTwo as Looks2, Looks3, Looks4, Looks5, Looks6, Star as StarIcon, Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9 } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "number" && Number.isNaN(value) === true) ||
    (typeof value === "boolean" && value === false) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);

export const capitalizeFirst = (s) => {
    if (typeof s !== 'string') return '';
    // First lowercase everything in the string
    s = s.toLowerCase();
    // Then capitalise the first letter
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const capitalizeAllFirst = (s, separator = 0) => {
    if (typeof s !== 'string') return '';
    const split_string = s.split(separator ? separator : " ");
    const newStringArray = [];
    split_string.forEach(word => {
        const capitalizedWord = capitalizeFirst(word);
        newStringArray.push(capitalizedWord);
    });
    const finalString = newStringArray.join(" ");
    return finalString;
}

export const round = (value, decimals) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

// https://math.stackexchange.com/questions/1965497/how-can-i-find-a-random-position-between-two-points
// https://stackoverflow.com/questions/52890389/is-it-possible-to-choose-a-random-color-from-a-gradient-or-a-range-between-two-c
export const interpolate = (c1, c2) => {
    const u = Math.random(); // A value between 0 - 1
    const newValues = c1.map((a, i) => Math.floor((1 - u) * a + u * c2[i]));
    return `rgb(${newValues.join(',')})`;
};

export const ScrollToTop = () => {
    const history = useHistory();
    React.useEffect(() => {
        const unlisten = history.listen(() => { window.scrollTo(0, 0); });
        return () => { unlisten(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (null);
}

export const renderNumber = (num) => {
    switch (num) {
        case 1:
            return <Looks1 className="section-number" />
        case 2:
            return <Looks2 className="section-number" />
        case 3:
            return <Looks3 className="section-number" />
        case 4:
            return <Looks4 className="section-number" />
        case 5:
            return <Looks5 className="section-number" />
        case 6:
            return <Looks6 className="section-number" />
        case 7:
            return <StarIcon className="section-number" />
        default:
            return <div className="section-number">{num}</div>
    }
}

export const renderNumberFilter = (num) => {
    switch (num) {
        case 1:
            return <Filter1 className="section-number" />
        case 2:
            return <Filter2 className="section-number" />
        case 3:
            return <Filter3 className="section-number" />
        case 4:
            return <Filter4 className="section-number" />
        case 5:
            return <Filter5 className="section-number" />
        case 6:
            return <Filter6 className="section-number" />
        case 7:
            return <Filter7 className="section-number" />
        case 8:
            return <Filter8 className="section-number" />
        case 9:
            return <Filter9 className="section-number" />
        default:
            return <div className="section-number">{num}</div>
    }
}