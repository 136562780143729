import React from 'react';

/**************************************************************************************************** 
 * CARD BLOB
****************************************************************************************************/

const CardBlobSmooth = () => {
    return (
        <svg style={{ width: 0, height: 0 }} xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                    <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
            </defs>
        </svg>
    )
}

/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const SVGFilters = {
    CardBlobSmooth,
}

export default SVGFilters;