import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Pages } from '../components';
import { ModalContext } from '../contexts';
import CapsuleImage from '../images/pill-cam-image.png';
import ErrorImage from '../images/error.svg';

/**************************************************************************************************** 
 * MAIN BANNER
****************************************************************************************************/

const MainBanner = styled("div", { shouldForwardProp: (prop) => prop !== "error" })(({ theme, error }) => ({
    minHeight: '100vh', padding: '0 40px', position: 'relative', backgroundImage: error ? `url(${ErrorImage})` : `url(${CapsuleImage})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { padding: '0 20px', },
    '&:before': { position: 'absolute', top: 0, left: 0, content: "''", width: '100%', height: '100%', background: theme.palette.gradient.light, opacity: "0.85", },
    '& .content': {
        zIndex: 99, textAlign: "center", color: "#FFF", width: '100%',
        [theme.breakpoints.down('sm')]: { paddingTop: 50 },
        '& .logo-text': { fontFamily: "'Comfortaa', sans-serif", fontSize: '50px', letterSpacing: 4, },
        '& .main-title': { fontFamily: "'Poppins', sans-serif", },
        '& .sub-title': { maxWidth: 600, margin: '20px auto', },
    }
}));

/**************************************************************************************************** 
 * MINI BANNER
****************************************************************************************************/

const MiniBannerWrapper = styled("div")(({ theme }) => ({
    padding: '40px 20px', margin: '40px auto 0', textAlign: 'left', maxWidth: '80%', color: '#FFF',
    [theme.breakpoints.down('md')]: { maxWidth: '100%', },
    "&.timeline-page": {
        marginBottom: -160,
        [theme.breakpoints.up('sm')]: { marginBottom: "-80px" },
        [theme.breakpoints.up('md')]: { marginBottom: 0 },
    },
    '& .title': {
        fontFamily: "'Comfortaa', sans-serif", fontSize: '50px', letterSpacing: 4, display: 'flex', alignItems: 'center',
        '& .change-page-btn': { width: 160, marginLeft: 10, },
    },
    '& .sub-title': { letterSpacing: 1, fontFamily: "'Poppins', sans-serif", },
    '& .blurb': {
        marginTop: 20,
        '& .emphasise': {
            fontWeight: 600,
            '&.primary': { color: theme.palette.primary.main },
            '&.secondary': { color: theme.palette.secondary.main },
            '&.danger': { color: 'red' },
        },
    },
}));

function MiniBanner({ title, blurb, additionalComponent, ...props }) {
    const { dispatchModal } = React.useContext(ModalContext.context);
    return (
        <MiniBannerWrapper {...props}>
            <Typography className="title">
                <span>NΞO</span>
                <Button className="change-page-btn" variant="action" onClick={() => dispatchModal({ type: "OPEN", payload: <Pages /> })}>Change Page</Button>
            </Typography>
            <Typography className="sub-title" variant="h2">{title}</Typography>
            <Typography className="blurb" variant="h4">{blurb}</Typography>
            {additionalComponent}
        </MiniBannerWrapper>
    )
}



/**************************************************************************************************** 
 * EXPORT MODULE
****************************************************************************************************/

const Banners = {
    Main: MainBanner,
    Mini: MiniBanner,
}

export default Banners;